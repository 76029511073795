<template>
  <div>
    <q-splitter
      class="full-height"
      v-model="splitterModel"
      style="height: 250px"
    >
      <template v-slot:before>
        <q-tabs v-model="tab" vertical class="text-teal">
          <q-tab
            name="學系特色"
            class="text-deep-orange-5 full-width material-icons"
            label="學系特色"
            >school</q-tab
          >
          <q-tab
            name="教學團隊"
            class="text-deep-orange-5 full-width material-icons"
            label="教學團隊"
            >contact_page</q-tab
          >
          <q-tab
            name="入學管道"
            class="text-deep-orange-5 full-width material-icons"
            label="入學管道"
            >how_to_reg</q-tab
          >
          <q-tab
            name="雙聯學制"
            class="text-deep-orange-5 full-width material-icons"
            label="雙聯學制"
            >handshake</q-tab
          >
        </q-tabs>
      </template>

      <template v-slot:after>
        <q-tab-panels
          v-model="tab"
          animated
          vertical
          transition-prev="jump-up"
          transition-next="jump-up"
        >
          <q-tab-panel name="學系特色">
            <q-img
              src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2527_9524931_87323.png"
            ></q-img>
          </q-tab-panel>
          <q-tab-panel name="教學團隊">
            <div class="q-pa-md">
              <div class="row q-gutter-sm">
                <q-intersection
                  v-for="(member, idx) in teamMembers"
                  :key="idx"
                  once
                  transition="scale"
                  class="team-member"
                >
                  <q-card class="my-card" flat bordered>
                    <q-card-section horizontal>
                      <q-card-section style="height: 220px" class="q-pt-xs">
                        <div class="text-overline">{{ member.title }}</div>
                        <div class="text-h6 q-mt-sm q-mb-xs">
                          {{ member.name }}
                        </div>
                        <div class="text-caption text-grey">
                          {{ member.education }}
                          <div v-html="member.attachment"></div>
                        </div>
                        <div>
                          {{ member.profession }}
                        </div>
                      </q-card-section>

                      <q-card-section class="col-4 flex flex-center">
                        <q-img
                          class="fit rounded-borders"
                          :src="member.image"
                        />
                      </q-card-section>
                    </q-card-section>

                    <q-separator />

                    <q-card-actions>
                      <!-- <q-btn flat round icon="mail" /> -->
                      {{ member.email }}
                    </q-card-actions>
                  </q-card>
                </q-intersection>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="入學管道">
            <div class="text-h4 q-mb-md">入學管道</div>
            <div class="q-pa-md">
              <q-btn
                color="blue-grey-6"
                class="full-width"
                label="入學管道"
                type="a"
                href="https://recruit.kh.usc.edu.tw/"
                target="_blank"
              />
            </div>
          </q-tab-panel>
          <q-tab-panel name="雙聯學制">
            <div class="text-h4 q-mb-md">雙聯學制</div>
            <div class="text-h6">
              雙聯學制 Dual degrees program <br />
              全國大學唯一服裝設計科系擁有雙聯學制。<br />
              本系學生申請赴海外雙聯學制者，於姐妹校修習學分，<br />
              且達姐妹校及本校之成績要求，並完成相關手續，<br />
              可獲得實踐大學及姐妹校之文憑。
            </div>
            <div class="q-pa-md">
              <q-btn
                color="blue-grey-6"
                class="full-width"
                label="雙聯學制"
                type="a"
                href="https://rc024.kh.usc.edu.tw/var/file/35/1035/img/826/166545454.pdf"
                target="_blank"
              />
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </template>
    </q-splitter>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref } from "vue";

export default {
  setup() {
    return {
      tab: ref("學系特色"),
      splitterModel: ref(20),
      teamMembers: [
        {
          name: "黃崇信",
          title: "主任/助理教授",
          email: "leo073173@yahoo.com (ext:5710)",
          education: "諾桑比亞大學(英國)設計碩士",
          profession:
            "服飾經營、服飾行銷、服飾消費行為、零售業管理、服飾採購、商品企劃、服飾產業概論、服飾展演活動企劃",
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2482_7993049_55379.jpg"
        },
        {
          name: "楊宜昌",
          title: "副教授",
          email: "yangyichang@g2.usc.edu.tw",
          education: "英國 Manchester Metropolitan University, 國際行銷博士",
          profession: "服裝設計、品牌設計與行銷、消費者行為、美學與產品設計",
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2477_5992937_55116.jpg"
        },
        {
          name: "林金台",
          title: "副教授",
          email: "j55365536@gmail.com (ext:6147)",
          education: "中國文化大學藝術研究所碩士",
          profession: "藝術創作與鑑賞、設計表現技法、美學形式、藝術史",
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2478_8788291_55156.jpg"
        },
        {
          name: "謝建騰",
          title: "助理教授",
          email: "edo@g2.usc.edu.tw (ext:6206)",
          education: "國立台灣科技大學纖維暨高分子博士",
          profession:
            "ADOBE系列軟體、電腦網路行銷、資料庫系統設計與建置、論文寫作架構分析、智慧型控制器設計",
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2481_1602905_55357.jpg"
        },
        {
          name: "郭月慈",
          title: "助理教授",
          email: "yeats.kuo@g2.usc.edu.tw (ext:5304)",
          education: "樹德科技大學應用設計研究所碩士",
          profession: "服裝製作、版型設計、國服製作、立體裁剪",
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2483_151633_55402.jpg"
        },
        {
          name: "邵淑萍",
          title: "助理教授",
          email: "sophieshao65@hotmail.com",
          education: "英國曼徹斯特大學行銷與流通碩士",
          profession: "服裝構成製作、服飾經營、外銷成衣實務、色彩學、服飾行銷",
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2484_2245447_55441.jpg"
        },
        {
          name: "周伸芳",
          title: "助理教授",
          email: "csf@g2.usc.edu.tw",
          education: "英國 University of Warwick, 全球史與文化研究博士",
          profession: "全球設計史、科技藝術策展、時尚設計研究、品牌經營管理",
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3063_5777680_42499.jpg"
        },
        {
          name: "曾梅芳",
          title: "助理教授級專業技術人員",
          email: "at611013@gmail.com (ext:6310)",
          education: "實踐大學服裝設計研究所碩士",
          profession:
            "服裝設計、立體裁剪與設計、西洋服裝史、中國服裝史、3D樣板與數位應用設計",
          attachment: `<a target="_blank" href="https://fdm.kh.usc.edu.tw/var/file/35/1035/img/826/106803589.pdf">經歷及專業</a>`,
          image:
            "https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_2485_5758036_55464.jpg"
        }
      ]
    };
  },
  components: {}
};
</script>

<style>
.material-icons {
  font-size: 48px; /* Preferred icon size */
}
.my-card {
  max-width: 350px;
  width: 100%;
}
.team-member {
  height: 300px;
  width: 360px;
}
</style>
