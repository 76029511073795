<template>
  <q-page>
    <div class="about">
      <!-- <h1>This is an about page</h1> -->
      <AboutTabPanel />
    </div>
  </q-page>
</template>

<script>
// @ is an alias to /src
import AboutTabPanel from "@/components/AboutTabPanel.vue";

export default {
  setup() {
    return {};
  },
  name: "About",
  components: {
    AboutTabPanel
  }
};
</script>
